var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard dashboard-with-banner" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part" },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "box-header chart-header-area",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "px-0 mb-4" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("createCampaign.h2")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "text-subtitle-2 mb-3" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("createCampaign.para")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid1,
                                        callback: function ($$v) {
                                          _vm.valid1 = $$v
                                        },
                                        expression: "valid1",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.chooseCampGroup"
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      "item-text": "orgName",
                                                      "item-value": "orgId",
                                                      items:
                                                        _vm.getCampaignGroups,
                                                      placeholder: _vm.$t(
                                                        "createCampaign.chooseCamp"
                                                      ),
                                                      outlined: "",
                                                      dense: "",
                                                      rules:
                                                        _vm.campaignGroupRules,
                                                    },
                                                    model: {
                                                      value: _vm.orgId,
                                                      callback: function ($$v) {
                                                        _vm.orgId = $$v
                                                      },
                                                      expression: "orgId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.chooseApp"
                                                          )
                                                        ) + " "
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items: _vm.appList,
                                                      "search-input":
                                                        _vm.search,
                                                      outlined: "",
                                                      dense: "",
                                                      "item-text": "appName",
                                                      "item-value": "adamId",
                                                      "cache-items": "",
                                                      placeholder: _vm.$t(
                                                        "createCampaign.selectApp"
                                                      ),
                                                      rules: _vm.appRules,
                                                      "return-object": "",
                                                      loading:
                                                        _vm.isAppSearchLoading,
                                                    },
                                                    on: {
                                                      "update:searchInput": function (
                                                        $event
                                                      ) {
                                                        _vm.search = $event
                                                      },
                                                      "update:search-input": function (
                                                        $event
                                                      ) {
                                                        _vm.search = $event
                                                      },
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            [
                                                              _c("v-img", {
                                                                staticClass:
                                                                  "rounded-lg",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  "lazy-src":
                                                                    data.item
                                                                      .appIcon,
                                                                  "max-width":
                                                                    "30",
                                                                  "max-height":
                                                                    "30",
                                                                  "min-width":
                                                                    "20",
                                                                  "min-height":
                                                                    "20",
                                                                  src:
                                                                    data.item
                                                                      .appIcon,
                                                                },
                                                              }),
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "ml-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .appName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                    model: {
                                                      value: _vm.selectedApp,
                                                      callback: function ($$v) {
                                                        _vm.selectedApp = $$v
                                                      },
                                                      expression: "selectedApp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right pt-0",
                                      attrs: { cols: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "mx-5 primary--text" },
                                        [
                                          _vm._v("Step   "),
                                          _c("span", {}, [_vm._v("1")]),
                                          _vm._v("/4 "),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "w-xsm-100 mb-2",
                                          attrs: {
                                            width: "114",
                                            color: "primary",
                                            depressed: "",
                                            large: "",
                                            disabled: !_vm.valid1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setProperties()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("createCampaign.next")
                                              ) +
                                              " "
                                          ),
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "box-header chart-header-area",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "px-0 mb-4" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.countAndReg"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-subtitle-2 mb-3",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createCampaign.countPara"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid2,
                                        callback: function ($$v) {
                                          _vm.valid2 = $$v
                                        },
                                        expression: "valid2",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field mb-4" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.chooseCount"
                                                          )
                                                        ) + " "
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      multiple: "",
                                                      outlined: "",
                                                      placeholder: "Countries",
                                                      dense: "",
                                                      chips: "",
                                                      "small-chips": "",
                                                      label: "Countries",
                                                      items: _vm.appCountries,
                                                      "item-text": "Name",
                                                      "item-value": "Code",
                                                      rules: _vm.countryRules,
                                                      "deletable-chips": "",
                                                      "search-input":
                                                        _vm.searchInput,
                                                    },
                                                    on: {
                                                      "update:searchInput": function (
                                                        $event
                                                      ) {
                                                        _vm.searchInput = $event
                                                      },
                                                      "update:search-input": function (
                                                        $event
                                                      ) {
                                                        _vm.searchInput = $event
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.searchInput = null
                                                      },
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            [
                                                              _c(
                                                                "country-flag",
                                                                {
                                                                  attrs: {
                                                                    country: data.item.Code.toLowerCase(),
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .Name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                    model: {
                                                      value:
                                                        _vm.selectedCountries,
                                                      callback: function ($$v) {
                                                        _vm.selectedCountries = $$v
                                                      },
                                                      expression:
                                                        "selectedCountries",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.campName"
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules:
                                                        _vm.campaignNameRules,
                                                    },
                                                    model: {
                                                      value: _vm.campaignName,
                                                      callback: function ($$v) {
                                                        _vm.campaignName = $$v
                                                      },
                                                      expression:
                                                        "campaignName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-0",
                                          attrs: { align: "center" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: {
                                                lg: "3",
                                                md: "3",
                                                sm: "6",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "field left-icon-field-area",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.budget"
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      "prepend-icon":
                                                        "mdi-currency-usd",
                                                      rules: _vm.budgetRules,
                                                    },
                                                    model: {
                                                      value: _vm.budget,
                                                      callback: function ($$v) {
                                                        _vm.budget = $$v
                                                      },
                                                      expression: "budget",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right pt-0",
                                      attrs: { cols: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "mx-5 primary--text" },
                                        [
                                          _vm._v("Step   "),
                                          _c("span", {}, [_vm._v("2")]),
                                          _vm._v("/4 "),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3 w-xsm-100 mb-2",
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            color: "disable",
                                            outlined: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.step = 1
                                            },
                                          },
                                        },
                                        [_vm._v(" Previous ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3 w-xsm-100 mb-2",
                                          attrs: {
                                            width: "114",
                                            color: "primary",
                                            elevation: "0",
                                            depressed: "",
                                            large: "",
                                            disabled:
                                              !_vm.valid2 ||
                                              _vm.savingCountriesRegions,
                                            loading: _vm.savingCountriesRegions,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveCountriesRegions()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("createCampaign.next")
                                              ) +
                                              " "
                                          ),
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: " ", attrs: { elevation: "0" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "box-header chart-header-area",
                                    },
                                    [
                                      _c("h3", { staticClass: "px-0 mb-4" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createCampaign.adgroupSettings"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "body-2 mb-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createCampaign.adgroupPara")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formStep3",
                                      model: {
                                        value: _vm.valid3,
                                        callback: function ($$v) {
                                          _vm.valid3 = $$v
                                        },
                                        expression: "valid3",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: " ",
                                              attrs: {
                                                lg: "6",
                                                md: "4",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.adgroupName"
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules:
                                                        _vm.adgroupNameRules,
                                                    },
                                                    model: {
                                                      value: _vm.adGroupName,
                                                      callback: function ($$v) {
                                                        _vm.adGroupName = $$v
                                                      },
                                                      expression: "adGroupName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: " ",
                                              attrs: {
                                                lg: "6",
                                                md: "8",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "field",
                                                      attrs: {
                                                        lg: "6",
                                                        md: "6",
                                                        sm: "12",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "black--text mb-3 d-flex pt-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createCampaign.startTime"
                                                              )
                                                            )
                                                          ),
                                                          _c(
                                                            "sup",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-datetime-picker", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          datePickerProps:
                                                            _vm.datePickerProps,
                                                        },
                                                        model: {
                                                          value: _vm.startdate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.startdate = $$v
                                                          },
                                                          expression:
                                                            "startdate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "btn my-2 d-flex align-center",
                                                      attrs: {
                                                        lg: "6",
                                                        md: "6",
                                                        sm: "12",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            width: "164",
                                                            color: "primary",
                                                            depressed: "",
                                                            large: "",
                                                            outlined: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.expandAdScheduling = !_vm.expandAdScheduling
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.expandAdScheduling
                                                                  ? "-"
                                                                  : "+"
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "createCampaign.adScheduling"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-expand-transition", [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.expandAdScheduling,
                                                            expression:
                                                              "expandAdScheduling",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "add-scheduling ml-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "field",
                                                          },
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                label:
                                                                  "End Time (Optional)",
                                                                color:
                                                                  "primary",
                                                                value: true,
                                                                "hide-details":
                                                                  "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.endTimeCheck,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.endTimeCheck = $$v
                                                                },
                                                                expression:
                                                                  "endTimeCheck",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              align: "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pt-0",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "field mt-10",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "black--text mb-0 d-flex",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createCampaign.endTime"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-datetime-picker",
                                                                      {
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          datePickerProps:
                                                                            _vm.datePickerProps,
                                                                          disabled: !_vm.endTimeCheck,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.enddate,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.enddate = $$v
                                                                          },
                                                                          expression:
                                                                            "enddate",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pt-0",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "field mt-2 mb-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "black--text mb-3 d-flex",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createCampaign.dayParting"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "pa-2 calendar-menu",
                                                                        attrs: {
                                                                          depressed:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          height:
                                                                            "42",
                                                                          block:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-menu",
                                                                          {
                                                                            ref:
                                                                              "menu",
                                                                            attrs: {
                                                                              "close-on-content-click": false,
                                                                              "return-value":
                                                                                _vm.date,
                                                                              transition:
                                                                                "scale-transition",
                                                                              "offset-y":
                                                                                "",
                                                                              "min-width":
                                                                                "auto",
                                                                            },
                                                                            on: {
                                                                              "update:returnValue": function (
                                                                                $event
                                                                              ) {
                                                                                _vm.date = $event
                                                                              },
                                                                              "update:return-value": function (
                                                                                $event
                                                                              ) {
                                                                                _vm.date = $event
                                                                              },
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function (
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-text-field",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              staticClass:
                                                                                                "pr-2 pl-3",
                                                                                              attrs: {
                                                                                                "append-icon":
                                                                                                  "mdi-calendar",
                                                                                                readonly:
                                                                                                  "",
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.date,
                                                                                                callback: function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.date = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "date",
                                                                                              },
                                                                                            },
                                                                                            "v-text-field",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ]
                                                                            ),
                                                                            model: {
                                                                              value:
                                                                                _vm.menu,
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.menu = $$v
                                                                              },
                                                                              expression:
                                                                                "menu",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-date-picker",
                                                                              {
                                                                                attrs: {
                                                                                  "no-title":
                                                                                    "",
                                                                                  scrollable:
                                                                                    "",
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.date,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.date = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "date",
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-spacer"
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      text:
                                                                                        "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.menu = false
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "createCampaign.cancel"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      text:
                                                                                        "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$refs.menu.save(
                                                                                          _vm.date
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "createCampaign.ok"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: " ",
                                              attrs: {
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex select-ipad-iphone",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.devices"
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.Devices,
                                                      placeholder: _vm.$t(
                                                        "createCampaign.selectDev"
                                                      ),
                                                      outlined: "",
                                                      dense: "",
                                                      rules: _vm.deviceRules,
                                                      multiple: "",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .src
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "ml-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .text
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                    model: {
                                                      value: _vm.selectDevices,
                                                      callback: function ($$v) {
                                                        _vm.selectDevices = $$v
                                                      },
                                                      expression:
                                                        "selectDevices",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: " ",
                                              attrs: {
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.defaultMax"
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      "append-icon":
                                                        "mdi-currency-usd",
                                                      rules: _vm.cptBidRules,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.defaultBidAmount,
                                                      callback: function ($$v) {
                                                        _vm.defaultBidAmount = $$v
                                                      },
                                                      expression:
                                                        "defaultBidAmount",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: " ",
                                              attrs: {
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v("App Category"),
                                                      _c(
                                                        "sup",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.categoryItems,
                                                      required: "",
                                                      outlined: "",
                                                      "item-text":
                                                        "categoryName",
                                                      "item-value": "value",
                                                      "return-object": "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value: _vm.categoryItem,
                                                      callback: function ($$v) {
                                                        _vm.categoryItem = $$v
                                                      },
                                                      expression:
                                                        "categoryItem",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "my-2 text-right" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "mx-5 primary--text" },
                                    [
                                      _vm._v("Step   "),
                                      _c("span", {}, [_vm._v("3")]),
                                      _vm._v("/4 "),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-3 w-xsm-100 mb-2",
                                      staticStyle: { width: "120px" },
                                      attrs: { color: "disable", outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 2
                                        },
                                      },
                                    },
                                    [_vm._v(" Previous ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "w-xsm-100 mb-2",
                                      attrs: {
                                        width: "114",
                                        color: "primary",
                                        depressed: "",
                                        large: "",
                                        disabled:
                                          !_vm.valid3 || _vm.startdate == null,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveAdGroupObject()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("createCampaign.next")
                                          ) +
                                          " "
                                      ),
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "4" } },
                            [
                              _c(
                                "div",
                                { staticClass: "box-header chart-header-area" },
                                [
                                  _c("h3", { staticClass: "px-0 mb-4" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createCampaign.audience")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "body-2 mb-6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("createCampaign.audPara")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.custType"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.customerTypes,
                                                      label: "Customer Types",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedCustomerType,
                                                      callback: function ($$v) {
                                                        _vm.selectedCustomerType = $$v
                                                      },
                                                      expression:
                                                        "selectedCustomerType",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "field d-flex" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-1 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.demo"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "mt-0 ml-3 pt-0",
                                                    attrs: {
                                                      label: "Age Range",
                                                    },
                                                    model: {
                                                      value: _vm.ageRange,
                                                      callback: function ($$v) {
                                                        _vm.ageRange = $$v
                                                      },
                                                      expression: "ageRange",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "range mt-3 px-4",
                                                },
                                                [
                                                  _c("v-range-slider", {
                                                    attrs: {
                                                      "thumb-label": true,
                                                      disabled: !_vm.ageRange,
                                                      step: "1",
                                                      min: "18",
                                                      max: "65",
                                                      "background-color":
                                                        "light",
                                                      "track-color": "grey",
                                                      "track-fill-color":
                                                        "primary",
                                                      "thumb-color": "primary",
                                                    },
                                                    model: {
                                                      value: _vm.ageRangeValue,
                                                      callback: function ($$v) {
                                                        _vm.ageRangeValue = $$v
                                                      },
                                                      expression:
                                                        "ageRangeValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12",
                                                cols: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.gender"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.gender,
                                                      label: "Gender",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value: _vm.selectedGender,
                                                      callback: function ($$v) {
                                                        _vm.selectedGender = $$v
                                                      },
                                                      expression:
                                                        "selectedGender",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.location"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items: _vm.locations,
                                                      disabled:
                                                        _vm.areMultipleCountries,
                                                      messages: _vm.areMultipleCountries
                                                        ? "Not available for campaign targeted for multiple countries"
                                                        : "",
                                                      "item-text":
                                                        "displayName",
                                                      "item-value": "id",
                                                      label: "Locations",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      multiple: "",
                                                      "hide-no-data": "",
                                                      loading: _vm.loading,
                                                      "search-input":
                                                        _vm.searchLoc,
                                                      "cache-items": "",
                                                    },
                                                    on: {
                                                      "update:searchInput": function (
                                                        $event
                                                      ) {
                                                        _vm.searchLoc = $event
                                                      },
                                                      "update:search-input": function (
                                                        $event
                                                      ) {
                                                        _vm.searchLoc = $event
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.searchLoc = ""
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedLocation,
                                                      callback: function ($$v) {
                                                        _vm.selectedLocation = $$v
                                                      },
                                                      expression:
                                                        "selectedLocation",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "my-2 text-right" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "mx-5 primary--text" },
                                    [
                                      _vm._v("Step   "),
                                      _c("span", {}, [_vm._v("4")]),
                                      _vm._v("/4 "),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-3 w-xsm-100 mb-2",
                                      staticStyle: { width: "120px" },
                                      attrs: { color: "disable", outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 3
                                        },
                                      },
                                    },
                                    [_vm._v(" Previous ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "w-xsm-100 mb-2",
                                      attrs: {
                                        depressed: "",
                                        large: "",
                                        width: "120",
                                        color: "primary",
                                        loading: _vm.savingCampaign,
                                        disabled:
                                          _vm.savingCampaign ||
                                          !_vm.valid1 ||
                                          !_vm.valid2 ||
                                          !_vm.valid3,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.appendAudience()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("createCampaign.createCamp")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    persistent: "",
                                    "max-width": "600px",
                                  },
                                  model: {
                                    value: _vm.successDialog,
                                    callback: function ($$v) {
                                      _vm.successDialog = $$v
                                    },
                                    expression: "successDialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-5" },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "d-flex justify-end pa-0 mb-10",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.closeSuccessDialog()
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "text-center py-10",
                                            },
                                            [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "pb-4 font-weight-medium black--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.campCreated"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.campCreatedPara"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-6",
                                                  attrs: { justify: "center" },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      "max-height": "173",
                                                      "max-width": "173",
                                                      src: require("../assets/images/success-check.svg"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        {
                                          staticClass: "justify-end mt-7 pa-0",
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                width: "127",
                                                color: "primary",
                                                depressed: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.closeSuccessDialog()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createCampaign.close"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }